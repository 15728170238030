import { createTheme } from '@mui/material/styles';
import styles from './index.module.scss';
// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: styles.mainBlue
    },
    secondary: {
      main: styles.mainGreen
    },
    error: {
      main: styles.mainRed
    }
  },
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',
    fontWeightRegular: 400,
    fontSize: 16
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1.15rem',
          fontWeight: '500',
          textTransform: 'none',
          height: '3.25rem',
          gap: '2rem',
          borderRadius: '0.5rem',
          boxShadow: 'none'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          padding: 0,
          fontSize: '1rem'
        },
        input: {
          padding: 0
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem',
          '&.Mui-focused': {
            top: '10px',
            color: styles.mainGrey
          },
          '&.Mui-error': {
            top: '10px',
            color: styles.mainRed
          },
          '&.MuiInputLabel-shrink': {
            top: '10px'
          },
          '&.MuiFormLabel-filled': {
            top: '10px'
          }
        },
        asterisk: {
          color: styles.mainRed
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '.MuiAutocomplete-input': {
            padding: '0'
          },
          boxSizing: 'border-box',
          backgroundColor: '#fff',
          borderRadius: '0.5rem',
          padding: 0,
          border: 'none',
          fieldset: {
            border: 'none',
            borderRadius: '0.5rem'
          },
          hasPopupIcon: {
            padding: '0 8px'
          },
          hasClearIcon: {
            padding: '0 8px'
          }
        },

        input: {
          boxSizing: 'border-box',
          padding: '15px 0 0 15px',
          height: '3.25rem'
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-root': {
            '.MuiAutocomplete-input': {
              padding: '15px 0 0 15px'
            }
          },
          '&.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root': {
            padding: '0 10px 0 0'
          }
        },
        inputRoot: {
          boxSizing: 'border-box',
          padding: '0',
          height: '3.25rem',
          cursor: 'pointer'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          // '.MuiOutlinedInput-root': {
          //   '.MuiAutocomplete-input': {
          //     padding: '15px 0 0 15px'
          //   }
          // },
          // '.MuiOutlinedInput-root': {
          //   padding: '10px 10px 0 0'
          // }
        },
        select: {
          boxSizing: 'border-box',
          padding: '20px 0 0 15px',
          height: '3.25rem',
          cursor: 'pointer'
        },
        nativeInput: {
          top: '20px',
        }
      }
    }
  }
});
export default theme;
