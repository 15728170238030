import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import apiClient from '../../shared/auth.interceptor';
import { userRefresh } from '../../shared/auth.functions';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentRealm } from '../../shared/redux/currentRealmSlicer';
import { errorToast } from '../../shared/toast.service';
import { RootState } from '../../../store';

export enum OAuthStatus {
  Success = 'OAuthSuccess',
  Error = 'OAuthError',
  DifferentRealmId = 'DifferentRealmId'
}

const Msg = () => (
  <div>
    <b> QuickBooks</b>
    <p> Opening your QuickBooks Account (popup window) to initiate the authorization </p>
  </div>
);
const useOAuth = () => {
  const companies = useSelector((state: RootState) => state.companyList.companyList);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation(); // Получаем текущее положение
  let previousPath: string; // Сохраняем текущий путь

  useEffect(() => {
    const handleMessage = async (event: MessageEvent) => {
      if (event.origin !== process.env.REACT_APP_UI_BASE_URL) {
        return;
      }
      if (event.data?.type === OAuthStatus.Success) {
        const updatedCompanies = await userRefresh(dispatch);
        dispatch(setCurrentRealm(null));
        const oldComp = companies?.find(c => c.realmId === event?.data?.realmId);
        if (location.pathname === '/' && !!updatedCompanies?.find(c => !c.firmName)) {
          navigate(`/user/dashboard`);
          return;
        } else if (location.pathname === '/') {
          navigate(`/user/clients`);
          return;
        }
        if (oldComp) {
          navigate(previousPath);
        } else {
          navigate(`/user/dashboard?realmId=${event.data.realmId}`, { replace: true });
        }
      }
      if (event.data?.type === OAuthStatus.Error) {
        dispatch(setCurrentRealm(null));
        errorToast('Error while authorizing QuickBooks');
      }
      if (event.data?.type === OAuthStatus.DifferentRealmId) {
        dispatch(setCurrentRealm(null));
        errorToast(
          'Unable to connect: The company you\'re authorizing in Intuit doesn\'t match the one you\'ve selected to connect');
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);
  const goToOauthPage = async () => {
    const response = await apiClient.post(`/v1/auth/oauth2/authUri`);
    toast(<Msg/>, { type: 'success', className: 'toast-message' });

    // Размеры окна
    const width = 620;
    const height = 700;

    // Рассчитываем позицию окна для его центрирования
    const left = (window.screen.width / 2) - (width / 2);
    const top = (window.screen.height / 2) - (height / 2);

    window.open(response.data, '_blank',
      `toolbar=no, menubar=no, width=${width}, height=${height}, top=${top}, left=${left}`);
  };


  return { goToOauthPage };
};

export default useOAuth;
