import './userContainer.scss';
import MenuComponent from './menuComponent';
import { Route, Routes } from 'react-router-dom';
import ClientsList from './clients/clients-list';
import PageNotFound from '../../pageNotFound';
import UserDashboard from './dashdoard/dashboard';
import PaymentList from './payment/payment';
import Details from './details/details';
import JournalTemplates from './clients/components/journal-list';
import BaseFormComponent from './clients/components/base-template-form';

export enum UserRoute {
  Clients = '/clients',
  Dashboard = '/dashboard',
  Payments = '/payments',
  Details = '/details',

  Templates = '/templates',
  RealmId = '/:realmId',
  TemplateId = '/:templateId',
  Create = '/create',
  Edit = '/edit',
  View = '/view',

}

const UserContainer = () => (
  <div className="userContainer">
    <div className="menu">
      <MenuComponent/>
    </div>
    <div className="contentContainerRow">
      <div className="contentContainer">
        <Routes>
          <Route path={UserRoute.Clients} element={<ClientsList/>}/>
          <Route path={UserRoute.Dashboard} element={<UserDashboard/>}/>
          <Route path={UserRoute.Payments} element={<PaymentList/>}/>
          <Route path={UserRoute.Details} element={<Details/>}/>
          <Route path={`${UserRoute.Clients}${UserRoute.Templates}${UserRoute.RealmId}`}
                 element={<JournalTemplates/>}/>
          <Route path={`${UserRoute.Clients}${UserRoute.Templates}${UserRoute.RealmId}${UserRoute.Create}`}
                 element={<BaseFormComponent mode="create"/>}/>
          <Route
            path={`${UserRoute.Clients}${UserRoute.Templates}${UserRoute.RealmId}${UserRoute.Edit}${UserRoute.TemplateId}`}
            element={<BaseFormComponent mode="edit"/>}/>
          <Route
            path={`${UserRoute.Clients}${UserRoute.Templates}${UserRoute.RealmId}${UserRoute.View}${UserRoute.TemplateId}`}
            element={<BaseFormComponent mode="view"/>}/>
          <Route path="*" element={<PageNotFound/>}/>
        </Routes>
      </div>
    </div>
  </div>
);
export default UserContainer;
