import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Company } from '../../user/clients/clients-list';

export interface CompanyListState {
  companyList: Company[] | null;
}

const initialState: CompanyListState = {
  companyList: null
};

export const companyListSlice = createSlice({
  name: 'companyList',
  initialState,
  reducers: {
    setCurrentCompanyList: (state, action: PayloadAction<Company[] | null>) => {
      state.companyList = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setCurrentCompanyList } = companyListSlice.actions;

export default companyListSlice.reducer;
