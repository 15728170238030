import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { AccountQB, Company } from '../clients-list';
import Breadcrumbs from '../../../shared/breadcrumb';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Autocomplete, Box,
  Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, InputAdornment,
  InputLabel, MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField, Tooltip,
  Typography
} from '@mui/material';
import styles from '../../../../index.module.scss';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import apiClient from '../../../shared/auth.interceptor';
import { errorToast } from '../../../shared/toast.service';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { MainRoute } from '../../../../app';
import { UserRoute } from '../../userContainer';
import { formatTimeOffset } from '../../../shared/date-time.formater';
import Loader from '../../../shared/Loader/loader';
import { toast } from 'react-toastify';
import ConfirmModal from './confirm.modal';

export interface JournalTemplateResponse extends JournalTemplateBase {
  startDate: string,
  endDate: string,
  postingTime: { hour: number, minute: number }
}

export interface JournalTemplateBase {
  _id?: string;
  active: boolean;
  realmId: string;
  lastPostingDate: string;
  nextPostingDate: string;
  // 1
  name: string,
  notes: string,
  // 2
  debitAccount: AccountQB | null,
  creditAccount: AccountQB | null,
  journalEntryNumberPrefix: string,
  description: string,
  // 3
  // startDate: DateTime | null,
  // endDate: DateTime | null,
  referenceAccount: AccountQB | null,
  balanceType: string,
  ratePerJournalEntry: string,
  // 4
  frequency: string,
  calcNumber: string,
  // postingTime: DateTime | null,
  referenceAccountPeriod: string,
  postingNumber: string
}

export interface JournalTemplate extends JournalTemplateBase {
  startDate: DateTime | null,
  endDate: DateTime | null,
  postingTime: DateTime | null,
}

interface TemplateFormProps {
  mode: 'create' | 'edit' | 'view';
  template?: JournalTemplate; // This type should be defined based on your data structure
  // onSave: (data: JournalTemplate) => void; // Callback to save the data
}

const daysOfWeek = [
  { value: 0, label: 'Sunday' },
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' }
];
const PostingTooltip = () => {
  return (
    // <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center' }}>
    <Tooltip
      placement="right"
      title="This is the posting date that affects the financial statements."
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: styles.mainGrey, // Изменение фона
            color: styles.mainWhite, // Цвет текста
            maxWidth: 'none', // Убираем ограничение по ширине
            whiteSpace: 'pre-wrap', // Разрешаем перенос текста
            fontSize: '10px' // Размер шрифта
            // padding: '0.5rem' // Отступы
          }
        },
        popper: {
          sx: {
            maxWidth: '700px' // Ограничение максимальной ширины для popper
          }
        }
      }}
    >
      <InfoOutlinedIcon sx={{ color: styles.mainRed, fontSize: '1rem', marginBottom: '0.5rem' }}/>
    </Tooltip>
    // </Box>
  );
};
const BaseFormComponent: React.FC<TemplateFormProps> = ({ mode }) => {
  const navigate = useNavigate();
  const { realmId } = useParams(); // Получение realmId из URL
  const { templateId } = useParams();
  const companies = useSelector((state: RootState) => state.companyList.companyList);
  const [selectedClient, setSelectedClient] = useState<Company | null>(null);
  const [accountList, setAccountList] = useState<AccountQB[]>([]);
  const [openedDebitAccount, setOpenDebitAccount] = React.useState(false);
  const [openedCreditAccount, setOpenCreditAccount] = React.useState(false);
  const [openedReferenceAccount, setOpenReferenceAccount] = React.useState(false);
  const [readOnly, setReadOnly] = React.useState(false);
  const [isDisableRepostLastTransaction, setDisableRepostLastTransaction] = React.useState(true);
  const [openConfirmModal, setConfirmModal] = useState(false);

  const [isEndDateEnabled, setIsEndDateEnabled] = useState(false);
  const [monthlyCalcSpecific, setMonthlyCalcSpecific] = useState('0');
  const [monthlyPostSpecific, setMonthlyPostSpecific] = useState('0');
  const { control, handleSubmit, setValue, watch, reset, formState } = useForm<JournalTemplate>({
    defaultValues: {
      _id: '',
      active: true,
      realmId,
      // 1
      name: '',
      notes: '',
      // 2
      debitAccount: null,
      creditAccount: null,
      journalEntryNumberPrefix: '',
      description: '',
      // 3
      startDate: null,
      endDate: null,
      referenceAccount: null,
      balanceType: '',
      ratePerJournalEntry: '',
      // 4
      frequency: 'Daily',
      calcNumber: '1',
      postingTime: DateTime.now().setZone(selectedClient?.timezone).set({ hour: 23, minute: 59, second: 0 }),
      referenceAccountPeriod: '',
      postingNumber: '1'
    }, mode: 'onChange'
  });
  const frequency = watch('frequency');
  const referenceAccountPeriod = watch('referenceAccountPeriod');
  const calcNumber = watch('calcNumber');
  const postingNumber = watch('postingNumber');
  const crumbs = [
    { title: selectedClient?.firmName, href: `${MainRoute.User}${UserRoute.Clients}` },
    {
      title: selectedClient?.companyName, href: `${MainRoute.User}${UserRoute.Clients}${UserRoute.Templates}/${realmId}`
    },
    { title: 'Journal templates' }
    // { title: 'TimeZone: ' + selectedClient?.timezone }
  ];
  const accountFilter = () => {
    if (!accountList?.length) {
      return [];
    }
    return accountList?.filter((account: AccountQB) =>
      account.AccountType !== 'Accounts Receivable' &&
      account.AccountType !== 'Accounts Payable'
    );
  };

  useEffect(() => {
    const currentCompany = companies?.find((company) => company.realmId === realmId);
    setSelectedClient(currentCompany || null);
  }, [realmId]);
  const getAccounts = async () => {
    try {
      const accounts = await apiClient.get(`v1/user/accounts/${realmId}`);
      setAccountList(accounts.data);
    } catch (e) {
      errorToast('Error getting company accounts');
      setLoading(false);
      setIsLoadingError(true);
    }
  };
  const getTemplate = async () => {
    try {
      const response = await apiClient.get(`/v1/journal/template/${templateId}`);
      if (response.status === 200 && response.data) {
        return response.data; // Возвращаем данные, только если они успешно получены
      }
      throw new Error('Failed to fetch template');
    } catch (e) {
      errorToast('Error getting template');
      return null; // Возвращаем null в случае ошибки
    }
  };
  const prepareJournalTemplateData = (data: JournalTemplateResponse): JournalTemplate => {
    const timezone = selectedClient?.timezone;
    return Object.assign(data, {
      // lastPostingDate: data.lastPostingDate ? DateTime.fromISO(data.lastPostingDate) : null,
      // nextPostingDate: data.nextPostingDate ? DateTime.fromISO(data.nextPostingDate) : null,
      startDate: data.startDate ? DateTime.fromISO(data.startDate).setZone(timezone) : null,
      endDate: data.endDate ? DateTime.fromISO(data.endDate).setZone(timezone) : null,
      postingTime: data.postingTime ?
        DateTime.now().setZone(selectedClient?.timezone)
          .set({ hour: data.postingTime.hour || 23, minute: data.postingTime.minute || 59, second: 0 }) :
        null,

      // startDate: data.startDate ? DateTime.fromISO(data.startDate, { zone: 'utc' }).setZone(selectedClient?.timezone) :
      //   null,
      // endDate: data.endDate ? DateTime.fromISO(data.endDate, { zone: 'utc' }).setZone(selectedClient?.timezone) : null,
      // postingTime: data.postingTime ?
      //   DateTime.fromISO(data.postingTime, { zone: 'utc' }).setZone(selectedClient?.timezone) : null,

      // Убедитесь, что AccountQB также корректно обрабатываются, если они могут быть null
      debitAccount: data.debitAccount, // предположим, что AccountQB может быть null
      creditAccount: data.creditAccount,
      referenceAccount: data.referenceAccount


      ///

      // calcDailyPeriod: '',
      // referenceAccountPeriod: '0',
      // postingNumber: '1',
      // calcNumber: '1'
    });
  };
  useEffect(() => {
    if (mode === 'view') {
      setReadOnly(true);
    }
    if ((mode === 'edit' || mode === 'view') && templateId && accountList?.length) {

      getTemplate()
        .then((data: JournalTemplateResponse) => {
          if (data) { // Проверяем, что data не null
            reset(prepareJournalTemplateData(data));
            if (data.frequency === 'Monthly' && data.calcNumber && +data.calcNumber < 30) {
              setMonthlyCalcSpecific('1');
            }
            if (data.frequency === 'Monthly' && data.postingNumber && +data.postingNumber < 30) {
              setMonthlyPostSpecific('1');
            }
            if (data.lastPostingDate) {
              setDisableRepostLastTransaction(false);
            }
          }
        })
        .catch(e => {
          setIsLoadingError(true);
          errorToast('Failed to load template data');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [mode, templateId, reset, accountList]);
  useEffect(() => {
    void getAccounts();
  }, []);
  const onSubmit = async (data: JournalTemplate) => {
    try {
      let response;
      console.log(data.postingTime?.toISO());
      console.log(data.postingTime?.hour + ':' + data.postingTime?.minute);
      // return;
      const toSave = Object.assign(data,
        { postingTime: { hour: data.postingTime?.hour, minute: data.postingTime?.minute } });
      if (mode === 'create') {
        delete toSave._id;
        response = await apiClient.post(`/v1/journal/template`, toSave);
      } else {
        response = await apiClient.put(`/v1/journal/template/${toSave._id}`, toSave);
      }

      if (response.status === 200 || response.status === 201) {
        navigate(`${MainRoute.User}${UserRoute.Clients}${UserRoute.Templates}/${realmId}`);
      } else {
        errorToast(`Error ${mode === 'create' ? 'create' : 'edit'} Journal Template`);
      }
    } catch (error) {
      errorToast(`Error ${mode === 'create' ? 'create' : 'edit'} Journal Template`);
    }
  };
  const sendTestTransaction = async (data: JournalTemplate) => {
    try {
      // return;
      setConfirmModal(false);
      const toSave = Object.assign(data,
        { postingTime: { hour: data.postingTime?.hour, minute: data.postingTime?.minute } });
      const response = await apiClient.post(`/v1/journal/template/test`, toSave);

      if (response.status === 200 || response.status === 201) {
        toast.success('Test journal template sent successfully');
      } else {
        errorToast(`Failed to send test journal template`);
      }
    } catch (error) {
      errorToast(`An error occurred while sending test journal template`);
    }
  };
  const repostLastTransaction = async (data: JournalTemplate) => {
    try {
      // return;
      const toSave = Object.assign(data,
        { postingTime: { hour: data.postingTime?.hour, minute: data.postingTime?.minute } });
      const response = await apiClient.post(`/v1/journal/template/${data._id}/repost`, toSave);

      if (response.status === 200 || response.status === 201) {
        toast.success('Repost journal template successfully');
      } else {
        errorToast(`Failed to repost journal template`);
      }
    } catch (error) {
      errorToast(`An error occurred while repost journal template`);
    }
  };
  const getPageTitle = () => {
    switch (mode) {
      case 'create':
        return 'Create template';
      case 'edit':
        return 'Edit template';
      case 'view':
        return 'View template';
    }
  };
  const numberPattern = /^\d*\.?\d*$/;
  const daysArray = Array.from({ length: 30 }, (_, i) => i + 1); // Создаем массив значений от 1 до 30

  const [loading, setLoading] = useState<boolean>(true);
  const [isLoadingError, setIsLoadingError] = useState<boolean>(false);
  // if (loading) {
  //   return <div><Loader/></div>;
  // }
  const handleSave = async (data: JournalTemplate) => {
    try {
      const toSave = {
        ...data,
        postingTime: {
          hour: data.postingTime?.hour,
          minute: data.postingTime?.minute
        }
      };

      let response;
      if (mode === 'create') {
        delete toSave._id;
        response = await apiClient.post(`/v1/journal/template`, toSave);
      } else {
        response = await apiClient.put(`/v1/journal/template/${toSave._id}`, toSave);
      }

      if (response.status === 200 || response.status === 201) {
        toast.success(`Journal template ${mode === 'create' ? 'created' : 'updated'} successfully`);
        // Отправка тестового запроса после успешного сохранения
        await sendTestTransaction(data);
        setConfirmModal(false);
      } else {
        errorToast(`Failed to send test journal template`);
      }
    } catch (error) {
      errorToast(`An error occurred while sending test journal template`);
    }
  };
  return (
    <>
      <div className="mt-2">
        <Breadcrumbs crumbs={crumbs}/>
      </div>
      <div className="d-flex align-items-center my-4">
        <ArrowBackIcon onClick={() => navigate(-1)}
                       sx={{ fontSize: '2rem', cursor: 'pointer' }}/>
        <Typography sx={{ fontSize: '2rem', marginLeft: '1rem', fontWeight: 'bol  d' }}>{getPageTitle()}</Typography>
      </div>
      {isLoadingError ?
        <Box></Box> :
        <Box sx={{ position: 'relative' }}>
          {mode !== 'create' && loading && <Loader/>}
          <form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
            <div className="row gx-3 gx-xl-4 gx-xxl-5">


              {/*Block 1*/}
              <div className="col-3">
                <Typography variant="subtitle2" color="#3FC7F4" gutterBottom className="mb-3">
                  Templates Info
                </Typography>
                <Controller name="name"
                            control={control}
                            rules={{
                              required: 'Template name is required',
                              minLength: { value: 3, message: 'The minimum length must be at least 3 characters.' },
                              maxLength: { value: 50, message: 'The maximum length must not exceed 50 characters.' }
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                {...field}
                                fullWidth
                                InputProps={{ disabled: readOnly }}
                                label="Template Name"
                                error={!!error}  // Отображение ошибки, если она есть
                                helperText={error?.message}  // Сообщение об ошибке
                                required
                                sx={{
                                  fontSize: '1rem',
                                  margin: '0.5rem 0',
                                  input: {
                                    border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                                    borderRadius: '.5rem'
                                  }
                                }}
                              />
                            )}
                />
                <Controller name="notes"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                {...field}
                                fullWidth
                                InputProps={{ disabled: readOnly }}
                                multiline
                                rows={5}
                                label="Notes"
                                sx={{
                                  fontSize: '1rem',
                                  margin: '0.5rem 0',
                                  textArea: {
                                    border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                                    borderRadius: '.5rem'
                                  }
                                }}
                              />
                            )}
                />
              </div>
              {/*Block 2*/}
              <div className="col-3">
                <Typography variant="subtitle2" color="#3FC7F4" gutterBottom className="mb-3">
                  Journal Entry
                </Typography>
                <Controller
                  name="debitAccount"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      disabled={readOnly}
                      isOptionEqualToValue={(option, value) => option.Id === value.Id}  // сравнение значений
                      value={field.value!}  // текущее значение
                      onChange={(_, newValue) => field.onChange(newValue)}  // обработка изменения
                      options={accountFilter()}  // список опций
                      disableClearable
                      open={openedDebitAccount}
                      onOpen={() => setOpenDebitAccount(true)}
                      onClose={() => setOpenDebitAccount(false)}
                      getOptionLabel={(option) => `${option.AcctNum ? `${option.AcctNum} | ` : ''}${option.Name}`}
                      sx={{
                        fontSize: '1rem',
                        margin: '1.5rem 0 1rem 0',
                        border: `1px solid ${styles.lightGrey}`,
                        borderRadius: '.5rem'
                      }}
                      // PaperComponent={(props) => (
                      //   <Paper {...props} sx={{ minWidth: 400 }}/> // Здесь можно задать minWidth или width
                      // )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Debit Account"
                          required
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <InputAdornment position="end">
                                <ArrowForwardIosIcon sx={{ fontSize: '1rem' }}
                                                     onClick={() => {
                                                       if (!readOnly) {
                                                         setOpenDebitAccount(!openedDebitAccount);
                                                       }
                                                     }}/>
                              </InputAdornment>
                            )
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={`debit-${option.Id}`}>
                          {`${option.AcctNum ? `${option.AcctNum} | ` : ''}${option.Name}`}
                        </li>
                      )}
                    />
                  )}
                />
                <Controller
                  name="creditAccount"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      disabled={readOnly}
                      isOptionEqualToValue={(option, value) => option.Id === value.Id}  // сравнение значений
                      value={field.value!}  // текущее значение
                      onChange={(_, newValue) => field.onChange(newValue)}
                      options={accountFilter()}
                      disableClearable
                      open={openedCreditAccount}
                      onOpen={() => setOpenCreditAccount(true)}
                      onClose={() => setOpenCreditAccount(false)}
                      getOptionLabel={(option) => `${option.AcctNum ? `${option.AcctNum} | ` : ''}${option.Name}`}
                      sx={{
                        fontSize: '1rem',
                        margin: '0.5rem 0',
                        border: `1px solid ${styles.lightGrey}`,
                        borderRadius: '.5rem'
                      }}
                      // PaperComponent={(props) => (
                      //   <Paper {...props} sx={{ minWidth: 400 }}/> // Здесь можно задать minWidth или width
                      // )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Credit Account"
                          required
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <InputAdornment position="end">
                                <ArrowForwardIosIcon sx={{ fontSize: '1rem' }}
                                                     onClick={() => {
                                                       if (!readOnly) {
                                                         setOpenCreditAccount(!openedCreditAccount);
                                                       }
                                                     }}/>
                              </InputAdornment>
                            )
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={`debit-${option.Id}`}>
                          {`${option.AcctNum ? `${option.AcctNum} | ` : ''}${option.Name}`}
                        </li>
                      )}
                    />
                  )}
                />
                <Controller
                  name="journalEntryNumberPrefix"
                  control={control}
                  rules={{
                    required: 'Journal Entry Number Prefix is required', // Обязательное поле
                    minLength: { value: 1, message: 'The prefix must have at least 1 character' }, // Минимум 1 символ
                    maxLength: { value: 6, message: 'The prefix can have at most 6 characters' } // Максимум 6 символов
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      disabled={readOnly}
                      fullWidth
                      label="Journal Entry Number Prefix"
                      // type="number" // Устанавливаем тип input как number
                      error={!!error} // Используется для отображения состояния ошибки
                      // placeholder="123"
                      helperText={error ? error.message : ''}
                      required
                      sx={{
                        fontSize: '1rem',
                        margin: '0.5rem 0',
                        input: {
                          border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                          borderRadius: '.5rem'
                        }
                      }}
                    />
                  )}
                />
                <Controller
                  name="description"
                  control={control}
                  rules={{
                    required: 'Description name is required',
                    minLength: { value: 5, message: 'Minimum length should be 5 characters' }
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      disabled={readOnly}
                      fullWidth
                      multiline
                      rows={8}
                      label="Description"
                      error={!!error} // Используется для отображения состояния ошибки
                      helperText={error ? error.message : ''}
                      required
                      sx={{
                        fontSize: '1rem',
                        margin: '0.5rem 0',
                        textArea: {
                          border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                          borderRadius: '.5rem'
                        }
                      }}
                    />
                  )}
                />
              </div>
              {/*Block 3*/}
              <div className="col-3">
                <Typography variant="subtitle2" color="#3FC7F4" gutterBottom className="mb-3">
                  Journal Dates
                </Typography>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
                      <DatePicker
                        disabled={readOnly}
                        label="Start date"
                        value={value}
                        onChange={onChange}
                        timezone={selectedClient?.timezone}
                        ref={ref}
                        slotProps={{
                          textField: {
                            error: !!error,
                            helperText: error ? error.message : '',
                            fullWidth: true,
                            required: true,
                            variant: 'outlined',
                            sx: {
                              width: '100%',
                              margin: '0.5rem 0',
                              border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                              borderRadius: '.5rem',
                              paddingRight: '1rem'
                            }
                          }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={readOnly}
                        checked={isEndDateEnabled}
                        onChange={(e) => {
                          setIsEndDateEnabled(e.target.checked);
                          if (!e.target.checked) {
                            setValue('endDate', null);
                          }
                        }}
                        sx={{
                          margin: '1rem 0',
                          color: styles.mainGreen,
                          '&.Mui-checked': {
                            color: styles.mainGreen
                          },
                          '& .MuiSvgIcon-root': {
                            fontSize: 20
                          }
                        }}
                      />
                    }
                    label="End Date"
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: '1rem'
                      }
                    }}
                  />
                </FormGroup>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
                      <DatePicker
                        label="End date"
                        value={value}
                        onChange={onChange}
                        timezone={selectedClient?.timezone}
                        ref={ref}
                        disabled={!isEndDateEnabled || readOnly}
                        slotProps={{
                          textField: {
                            error: !!error,
                            helperText: error ? error.message : '',
                            fullWidth: true,
                            variant: 'outlined',
                            sx: {
                              width: '100%',
                              margin: '0.5rem 0',
                              border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                              borderRadius: '.5rem',
                              paddingRight: '1rem'
                            }
                          }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <Typography variant="subtitle2" color="#3FC7F4" gutterBottom className="my-3">
                  Calculations
                </Typography>
                <Controller
                  name="referenceAccount"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      disabled={readOnly}
                      isOptionEqualToValue={(option, value) => option.Id === value.Id}  // сравнение значений
                      value={field.value!}  // текущее значение
                      onChange={(_, newValue) => field.onChange(newValue)}
                      options={accountList}
                      disableClearable
                      open={openedReferenceAccount}
                      onOpen={() => setOpenReferenceAccount(true)}
                      onClose={() => setOpenReferenceAccount(false)}
                      getOptionLabel={(option) => `${option.AcctNum ? `${option.AcctNum} | ` : ''}${option.Name}`}
                      sx={{
                        fontSize: '1rem',
                        margin: '1.5rem 0 1rem 0',
                        border: `1px solid ${styles.lightGrey}`,
                        borderRadius: '.5rem'
                      }}
                      // PaperComponent={(props) => (
                      //   <Paper {...props} sx={{ minWidth: 400 }}/> // Здесь можно задать minWidth или width
                      // )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Reference Account"
                          required
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <InputAdornment position="end">
                                <ArrowForwardIosIcon sx={{ fontSize: '1rem' }}
                                                     onClick={() => {
                                                       if (!readOnly) {
                                                         setOpenReferenceAccount(!openedReferenceAccount);
                                                       }
                                                     }}/>
                              </InputAdornment>
                            )
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={`debit-${option.Id}`}>
                          {`${option.AcctNum ? `${option.AcctNum} | ` : ''}${option.Name}`}
                        </li>
                      )}
                    />
                  )}
                />
                <FormControl>
                  <FormLabel className="my-3" required
                             sx={{
                               '&.Mui-focused': { top: 0 },
                               fontSize: '1rem',
                               color: styles.mainGrey
                             }}>
                    Balance Type
                  </FormLabel>
                  <Controller
                    name="balanceType"
                    control={control}
                    rules={{
                      required: 'Balance Type is required'
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <RadioGroup {...field} row>
                          <FormControlLabel
                            disabled={readOnly}
                            value="Accrual"
                            control={<Radio sx={{
                              color: styles.mainGreen,
                              '&.Mui-checked': {
                                color: styles.mainGreen
                              },
                              '& .MuiSvgIcon-root': {
                                fontSize: '1rem'
                              }
                            }}/>}
                            label="Accrual"
                            sx={{
                              '& .MuiFormControlLabel-label': {
                                fontSize: '1rem'
                              }
                            }}
                          />
                          <FormControlLabel
                            disabled={readOnly}
                            value="Cash"
                            control={<Radio sx={{
                              color: styles.mainGreen,
                              '&.Mui-checked': {
                                color: styles.mainGreen
                              },
                              '& .MuiSvgIcon-root': {
                                fontSize: '1rem'
                              }
                            }}/>}
                            label="Cash"
                            sx={{
                              '& .MuiFormControlLabel-label': {
                                fontSize: '1rem'
                              }
                            }}
                          />
                        </RadioGroup>
                        {error && (
                          <FormHelperText sx={{ color: styles.mainRed }}>{error.message}</FormHelperText> // Вывод сообщения об
                          // ошибке
                        )}
                      </>
                    )}
                  />
                </FormControl>

                <Controller
                  name="ratePerJournalEntry"
                  control={control}
                  rules={{
                    required: '% Rate Per Journal Entry is required',
                    pattern: {
                      value: numberPattern,
                      message: 'Invalid format, only numbers and one decimal point are allowed' // Сообщение об ошибке формата
                    }
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      disabled={readOnly}
                      fullWidth
                      onChange={(e) => {
                        field.onChange(e); // Обновляет значение поля
                      }}
                      onBlur={(e) => {
                        field.onChange(e.target.value.trim()); // Обновляет значение поля после обрезки пробелов
                      }}
                      label="% Rate Per Journal Entry"
                      required
                      placeholder="0.0"
                      error={!!error}
                      helperText={error ? error.message : null} // Показывает сообщение об ошибке
                      sx={{
                        fontSize: '1rem',
                        margin: '1.5rem 0 0.5rem 0',
                        input: {
                          border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                          borderRadius: '.5rem'
                        }
                      }}
                    />
                  )}
                />
              </div>
              {/*Block 4*/}
              <div className="col-3">
                <Typography variant="subtitle2" color="#3FC7F4" gutterBottom className="mb-3">
                  Frequency
                  {/*<Tooltip className="ms-2" title={`Company Timezone: ${formatTimeOffset(selectedClient?.timezone)}`}>*/}
                  {/*  <InfoOutlinedIcon sx={{ color: styles.mainGrey, fontSize: '1rem', marginBottom: '0.5rem' }}/>*/}
                  {/*</Tooltip>*/}
                </Typography>
                <FormControl fullWidth>
                  <InputLabel id="frequency-label" required
                              sx={{ '&.MuiInputLabel-shrink': { top: '20px', left: '0' }, top: '11px' }}>
                    Frequency
                  </InputLabel>
                  <Controller
                    name="frequency"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Select
                        {...field}
                        disabled={readOnly}
                        labelId="frequency-label"
                        label="Frequency"
                        onChange={(e) => {
                          setValue('frequency', e.target.value);
                          switch (e.target.value) {
                            case 'Daily': {
                              setValue('calcNumber', '0');
                              setValue('postingNumber', '0');
                              break;
                            }
                            case 'Weekly': {
                              setValue('calcNumber', '6');
                              setValue('postingNumber', '6');
                              break;
                            }
                            case 'Monthly': {
                              setValue('calcNumber', '30');
                              setValue('postingNumber', '30');
                              setMonthlyCalcSpecific('0');
                              setMonthlyPostSpecific('0');
                              break;
                            }
                          }
                        }}
                        required
                        sx={{
                          width: '100%',
                          margin: '0.5rem 0',
                          border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                          borderRadius: '.5rem',
                          paddingRight: '1rem'
                        }}
                      >
                        <MenuItem value={'Daily'}>Daily</MenuItem>
                        <MenuItem value={'Weekly'}>Weekly</MenuItem>
                        <MenuItem value={'Monthly'}>Monthly</MenuItem>
                        {/*<MenuItem value={'Quarterly'}>Quarterly</MenuItem>*/}
                        {/*<MenuItem value={'Annual'}>Annual</MenuItem>*/}
                      </Select>
                    )}
                  />
                </FormControl>
                <Typography variant="subtitle2" color="#3FC7F4" gutterBottom className="my-3">
                  Calculated and Submitted at
                </Typography>
                {frequency === 'Daily' && <div>
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', width: '100%' }}>
                      <Controller
                        name="postingTime"
                        control={control}
                        rules={{ required: 'Posting Time is required' }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TimePicker
                            disabled={readOnly}
                            label="Time"
                            value={value}
                            onChange={onChange}
                            timeSteps={{ minutes: 1 }}
                            slotProps={{
                              textField: {
                                error: !!error,
                                helperText: error ? error.message : '',
                                fullWidth: true,
                                required: true,
                                style: { background: 'white' },
                                sx: {
                                  margin: '0.5rem 0',
                                  '& .MuiInputBase-root': {
                                    '&.MuiOutlinedInput-root': {
                                      border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                                      borderRadius: '.5rem',
                                      paddingRight: '.8rem' // Увеличим padding для размещения иконки и таймзоны
                                    }
                                  }
                                }
                              }
                            }}
                          />
                        )}
                      />
                      <Box
                        sx={{
                          position: 'absolute',
                          left: '6rem', // Позиционируем текст таймзоны справа от поля ввода
                          display: 'flex',
                          marginTop: '1rem',
                          alignItems: 'center',
                          height: '100%',
                          color: 'rgba(0, 0, 0, 0.54)',
                          pointerEvents: 'none' // Это позволит избежать конфликтов с кликами
                        }}
                      >
                        {selectedClient?.timezone && (
                          <Box component="span" sx={{ marginRight: '0.5rem' }}>
                            {formatTimeOffset(selectedClient.timezone)}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </LocalizationProvider>

                  <Typography variant="subtitle2" color="#3FC7F4" gutterBottom className="my-3">
                    Calculation period
                  </Typography>
                  <FormControl fullWidth>
                    <InputLabel id="calcDailyPeriod-label" required
                                sx={{ '&.MuiInputLabel-shrink': { top: '20px', left: '0' }, top: '11px' }}>
                      Period
                    </InputLabel>
                    <Controller
                      name="referenceAccountPeriod"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Select
                          {...field}
                          disabled={readOnly}
                          labelId="calcDailyPeriod-label"
                          label="Period"
                          required
                          onChange={(e) => {
                            setValue('referenceAccountPeriod', e.target.value);
                            setValue('postingNumber', '0');
                          }}
                          sx={{
                            width: '100%',
                            margin: '0.5rem 0',
                            border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                            borderRadius: '.5rem',
                            paddingRight: '1rem'
                          }}
                        >
                          <MenuItem value={'-1'}>Yesterday</MenuItem>
                          <MenuItem value={'0'}>Today</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                  <Typography variant="subtitle2" color="#3FC7F4" gutterBottom className="my-3">
                    Posting Date
                    <PostingTooltip/>
                  </Typography>
                  <FormControl>
                    <Controller
                      name="postingNumber"
                      control={control}
                      rules={{
                        required: 'Posting Date is required'
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <RadioGroup {...field} row
                          >
                            <FormControlLabel
                              disabled={readOnly || referenceAccountPeriod === '0'}
                              value="-1"
                              control={<Radio sx={{
                                color: styles.mainGreen,
                                '&.Mui-checked': {
                                  color: styles.mainGreen
                                },
                                '& .MuiSvgIcon-root': {
                                  fontSize: '1rem'
                                }
                              }}/>}
                              label="Yesterday"
                              sx={{
                                '& .MuiFormControlLabel-label': {
                                  fontSize: '1rem'
                                }
                              }}
                            />
                            <FormControlLabel
                              disabled={readOnly}
                              value="0"
                              control={<Radio sx={{
                                color: styles.mainGreen,
                                '&.Mui-checked': {
                                  color: styles.mainGreen
                                },
                                '& .MuiSvgIcon-root': {
                                  fontSize: '1rem'
                                }
                              }}/>}
                              label="Today"
                              sx={{
                                '& .MuiFormControlLabel-label': {
                                  fontSize: '1rem'
                                }
                              }}
                            />
                          </RadioGroup>
                          {error && (
                            <FormHelperText sx={{ color: styles.mainRed }}>{error.message}</FormHelperText> // Вывод сообщения об
                            // ошибке
                          )}
                        </>
                      )}
                    />
                  </FormControl>
                </div>}
                {frequency === 'Weekly' && <div>
                  <FormControl fullWidth>
                    <InputLabel id="calcDailyPeriod-label" required
                                sx={{ '&.MuiInputLabel-shrink': { top: '20px', left: '0' }, top: '11px' }}>
                      Day
                    </InputLabel>
                    <Controller
                      name="calcNumber"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Select
                          {...field}
                          disabled={readOnly}
                          labelId="calcDailyPeriod-label"
                          required
                          onChange={(e) => {
                            setValue('calcNumber', e.target.value);
                            if (referenceAccountPeriod === '0' && (+postingNumber > +e.target.value)) {
                              setValue('postingNumber', e.target.value);
                            }
                          }}
                          sx={{
                            width: '100%',
                            margin: '0.5rem 0',
                            border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                            borderRadius: '.5rem',
                            paddingRight: '1rem'
                          }}
                        >
                          {daysOfWeek.map(day => (
                            <MenuItem key={day.value} value={day.value}>
                              {day.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', width: '100%' }}>
                      <Controller
                        name="postingTime"
                        control={control}
                        rules={{ required: 'Posting Time is required' }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TimePicker
                            disabled={readOnly}
                            label="Time"
                            value={value}
                            onChange={onChange}
                            timeSteps={{ minutes: 1 }}
                            slotProps={{
                              textField: {
                                error: !!error,
                                helperText: error ? error.message : '',
                                fullWidth: true,
                                required: true,
                                style: { background: 'white' },
                                sx: {
                                  margin: '0.5rem 0',
                                  '& .MuiInputBase-root': {
                                    '&.MuiOutlinedInput-root': {
                                      border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                                      borderRadius: '.5rem',
                                      paddingRight: '.8rem' // Увеличим padding для размещения иконки и таймзоны
                                    }
                                  }
                                }
                              }
                            }}
                          />
                        )}
                      />
                      <Box
                        sx={{
                          position: 'absolute',
                          left: '6rem', // Позиционируем текст таймзоны справа от поля ввода
                          display: 'flex',
                          marginTop: '1rem',
                          alignItems: 'center',
                          height: '100%',
                          color: 'rgba(0, 0, 0, 0.54)',
                          pointerEvents: 'none' // Это позволит избежать конфликтов с кликами
                        }}
                      >
                        {selectedClient?.timezone && (
                          <Box component="span" sx={{ marginRight: '0.5rem' }}>
                            {formatTimeOffset(selectedClient.timezone)}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </LocalizationProvider>
                  <Typography variant="subtitle2" color="#3FC7F4" gutterBottom className="my-3">
                    Calculation period
                  </Typography>
                  <FormControl fullWidth>
                    <InputLabel id="calcDailyPeriod-label" required
                                sx={{ '&.MuiInputLabel-shrink': { top: '20px', left: '0' }, top: '11px' }}>
                      Period
                    </InputLabel>
                    <Controller
                      name="referenceAccountPeriod"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Select
                          {...field}
                          disabled={readOnly}
                          labelId="calcDailyPeriod-label"
                          label="Period"
                          required
                          onChange={(e) => {
                            setValue('referenceAccountPeriod', e.target.value);
                            if (e.target.value === '0' && (+postingNumber > +calcNumber)) {
                              setValue('postingNumber', calcNumber);
                            }
                          }}
                          sx={{
                            width: '100%',
                            margin: '0.5rem 0',
                            border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                            borderRadius: '.5rem',
                            paddingRight: '1rem'
                          }}
                        >
                          <MenuItem value={'-1'}>Last Week</MenuItem>
                          <MenuItem value={'0'}>This Week</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                  <Typography variant="subtitle2" color="#3FC7F4" gutterBottom className="my-3">
                    Posting Date
                    <PostingTooltip/>
                  </Typography>
                  <FormControl fullWidth>
                    <InputLabel id="calcDailyPeriod-label" required
                                sx={{ '&.MuiInputLabel-shrink': { top: '20px', left: '0' }, top: '11px' }}>
                      Day
                    </InputLabel>
                    <Controller
                      name="postingNumber"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Select
                          {...field}
                          disabled={readOnly}
                          labelId="calcDailyPeriod-label"
                          required
                          sx={{
                            width: '100%',
                            margin: '0.5rem 0',
                            border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                            borderRadius: '.5rem',
                            paddingRight: '1rem'
                          }}
                        >
                          {daysOfWeek.map(day => {
                            const disabled = referenceAccountPeriod === '0' && (+day.value > +calcNumber);
                            return (
                              <MenuItem key={day.value} value={day.value} disabled={disabled}>
                                {day.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </div>}
                {frequency === 'Monthly' && <div>
                  <RadioGroup row
                              onChange={(e) => {
                                setMonthlyCalcSpecific(e.target.value);
                                if (e.target.value === '1') {
                                  setMonthlyPostSpecific(e.target.value);
                                }
                              }}
                              defaultValue="0"
                              value={monthlyCalcSpecific}
                  >
                    <FormControlLabel
                      disabled={readOnly}
                      value="0"
                      control={<Radio
                        onClick={() => setValue('calcNumber', '30')}
                        sx={{
                          color: styles.mainGreen,
                          '&.Mui-checked': {
                            color: styles.mainGreen
                          },
                          '& .MuiSvgIcon-root': {
                            fontSize: '1rem'
                          }
                        }}/>}
                      label="End of Month"
                      sx={{
                        '& .MuiFormControlLabel-label': {
                          fontSize: '1rem'
                        }
                      }}
                    />
                    <FormControlLabel
                      disabled={readOnly}
                      value="1"
                      control={<Radio sx={{
                        color: styles.mainGreen,
                        '&.Mui-checked': {
                          color: styles.mainGreen
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: '1rem'
                        }
                      }}/>}
                      label="Specific Date"
                      sx={{
                        '& .MuiFormControlLabel-label': {
                          fontSize: '1rem'
                        }
                      }}
                    />
                  </RadioGroup>
                  {monthlyCalcSpecific === '1' ?
                    (<Box className="d-flex">
                      <FormControl sx={{ minWidth: '6rem', marginRight: '1rem' }}>
                        <InputLabel id="calcDailyPeriod-label" required
                                    sx={{ '&.MuiInputLabel-shrink': { top: '20px', left: '0' }, top: '11px' }}>
                          Day
                        </InputLabel>
                        <Controller
                          name="calcNumber"
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <Select
                              {...field}
                              disabled={readOnly}
                              labelId="calcDailyPeriod-label"
                              label="Period"
                              required
                              onChange={(e) => {
                                setValue('calcNumber', e.target.value);
                                if (referenceAccountPeriod === '0' && (+postingNumber > +e.target.value)) {
                                  setValue('postingNumber', e.target.value);
                                }
                              }}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 250
                                  }
                                }
                              }}
                              sx={{
                                width: '100%',
                                margin: '0.5rem 0',
                                border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                                borderRadius: '.5rem',
                                paddingRight: '1rem'
                              }}
                            >
                              {daysArray.map(day => (
                                <MenuItem key={day} value={day}>
                                  {day}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </FormControl>
                      <Tooltip title={`${formatTimeOffset(selectedClient?.timezone)}`} arrow
                               componentsProps={{
                                 tooltip: {
                                   sx: {
                                     bgcolor: styles.mainGrey,
                                     color: styles.mainWhite,
                                     maxWidth: 'none',
                                     whiteSpace: 'pre-wrap',
                                     fontSize: '10px'
                                   }
                                 },
                                 arrow: {
                                   sx: {
                                     color: styles.mainGrey
                                   }
                                 }
                               }}
                      >
                        <div>
                          <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', width: '100%' }}>
                              <Controller
                                name="postingTime"
                                control={control}
                                rules={{ required: 'Posting Time is required' }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TimePicker
                                    disabled={readOnly}
                                    label="Time"
                                    value={value}
                                    onChange={onChange}
                                    timeSteps={{ minutes: 1 }}
                                    slotProps={{
                                      textField: {
                                        error: !!error,
                                        helperText: error ? error.message : '',
                                        fullWidth: true,
                                        required: true,
                                        style: { background: 'white' },
                                        sx: {
                                          margin: '0.5rem 0',
                                          '& .MuiInputBase-root': {
                                            '&.MuiOutlinedInput-root': {
                                              border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                                              borderRadius: '.5rem',
                                              paddingRight: '.8rem' // Увеличим padding для размещения иконки и таймзоны
                                            }
                                          }
                                        }
                                      }
                                    }}
                                  />
                                )}
                              />
                            </Box>
                          </LocalizationProvider>
                        </div>
                      </Tooltip>
                    </Box>) :
                    (<div>
                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', width: '100%' }}>
                          <Controller
                            name="postingTime"
                            control={control}
                            rules={{ required: 'Posting Time is required' }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TimePicker
                                disabled={readOnly}
                                label="Time"
                                value={value}
                                onChange={onChange}
                                timeSteps={{ minutes: 1 }}
                                slotProps={{
                                  textField: {
                                    error: !!error,
                                    helperText: error ? error.message : '',
                                    fullWidth: true,
                                    required: true,
                                    style: { background: 'white' },
                                    sx: {
                                      margin: '0.5rem 0',
                                      '& .MuiInputBase-root': {
                                        '&.MuiOutlinedInput-root': {
                                          border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                                          borderRadius: '.5rem',
                                          paddingRight: '.8rem' // Увеличим padding для размещения иконки и таймзоны
                                        }
                                      }
                                    }
                                  }
                                }}
                              />
                            )}
                          />
                          <Box
                            sx={{
                              position: 'absolute',
                              left: '6rem', // Позиционируем текст таймзоны справа от поля ввода
                              display: 'flex',
                              marginTop: '1rem',
                              alignItems: 'center',
                              height: '100%',
                              color: 'rgba(0, 0, 0, 0.54)',
                              pointerEvents: 'none' // Это позволит избежать конфликтов с кликами
                            }}
                          >
                            {selectedClient?.timezone && (
                              <Box component="span" sx={{ marginRight: '0.5rem' }}>
                                {formatTimeOffset(selectedClient.timezone)}
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </LocalizationProvider>
                    </div>)}
                  <Typography variant="subtitle2" color="#3FC7F4" gutterBottom className="my-3">
                    Calculation period
                  </Typography>
                  <FormControl fullWidth>
                    <InputLabel id="calcDailyPeriod-label" required
                                sx={{ '&.MuiInputLabel-shrink': { top: '20px', left: '0' }, top: '11px' }}>
                      Period
                    </InputLabel>
                    <Controller
                      name="referenceAccountPeriod"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Select
                          {...field}
                          disabled={readOnly}
                          labelId="calcDailyPeriod-label"
                          label="Period"
                          required
                          onChange={(e) => {
                            setValue('referenceAccountPeriod', e.target.value);
                            if (e.target.value === '0' && (+postingNumber > +calcNumber)) {
                              setValue('postingNumber', calcNumber);
                            }
                          }}
                          sx={{
                            width: '100%',
                            margin: '0.5rem 0',
                            border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                            borderRadius: '.5rem',
                            paddingRight: '1rem'
                          }}
                        >
                          <MenuItem value={'-1'}>Last Month</MenuItem>
                          <MenuItem value={'0'}>This Month</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                  <Typography variant="subtitle2" color="#3FC7F4" gutterBottom className="my-3">
                    Posting Date
                    <PostingTooltip/>
                  </Typography>
                  <RadioGroup row
                              onChange={(e) => {
                                setMonthlyPostSpecific(e.target.value);
                              }}
                              defaultValue="0"
                              value={monthlyPostSpecific}
                  >
                    <FormControlLabel
                      disabled={readOnly || (referenceAccountPeriod === '0' && monthlyCalcSpecific === '1')}
                      value="0"
                      control={<Radio
                        onClick={() => setValue('postingNumber', '30')}
                        sx={{
                          color: styles.mainGreen,
                          '&.Mui-checked': {
                            color: styles.mainGreen
                          },
                          '& .MuiSvgIcon-root': {
                            fontSize: '1rem'
                          }
                        }}/>}
                      label="End of Month"
                      sx={{
                        '& .MuiFormControlLabel-label': {
                          fontSize: '1rem'
                        }
                      }}
                    />
                    <FormControlLabel
                      disabled={readOnly}
                      value="1"
                      control={<Radio sx={{
                        color: styles.mainGreen,
                        '&.Mui-checked': {
                          color: styles.mainGreen
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: '1rem'
                        }
                      }}/>}
                      label="Specific Date"
                      sx={{
                        '& .MuiFormControlLabel-label': {
                          fontSize: '1rem'
                        }
                      }}
                    />
                  </RadioGroup>
                  {monthlyPostSpecific === '1' ?
                    (<div>
                      <FormControl fullWidth>
                        <InputLabel id="calcDailyPeriod-label" required
                                    sx={{ '&.MuiInputLabel-shrink': { top: '20px', left: '0' }, top: '11px' }}>
                          Day
                        </InputLabel>
                        <Controller
                          name="postingNumber"
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <Select
                              {...field}
                              disabled={readOnly}
                              labelId="calcDailyPeriod-label"
                              label="Period"
                              required
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 250
                                  }
                                }
                              }}
                              sx={{
                                width: '100%',
                                margin: '0.5rem 0',
                                border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                                borderRadius: '.5rem',
                                paddingRight: '1rem'
                              }}
                            >
                              {daysArray.map(day => {
                                const disabled = referenceAccountPeriod === '0' && (day > +calcNumber);
                                return (
                                  <MenuItem key={day} value={day} disabled={disabled}>
                                    {day}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          )}
                        />
                      </FormControl>
                    </div>) :
                    (<></>)}
                </div>}

              </div>
            </div>
            <Divider component="div" sx={{ margin: '1rem 0' }}/>
            <div className="d-flex justify-content-between">
              <div className="d-flex justify-content-start mt-5">
                <Button variant="outlined"
                        color="secondary"
                        disabled={!formState.isValid}
                  // onClick={handleSubmit(sendTestTransaction)}
                        onClick={() => {
                          formState.isDirty ?
                            setConfirmModal(true) :
                            handleSubmit(sendTestTransaction)();
                        }}
                        sx={{
                          width: '296px'
                        }}
                >Post a test transaction</Button>
                <Tooltip title={isDisableRepostLastTransaction ? 'No transactions yet' : ''}
                         disableHoverListener={!isDisableRepostLastTransaction} arrow
                         componentsProps={{
                           tooltip: {
                             sx: {
                               bgcolor: styles.mainGrey,
                               color: styles.mainWhite,
                               maxWidth: 'none',
                               whiteSpace: 'pre-wrap',
                               fontSize: '10px'
                             }
                           },
                           arrow: {
                             sx: {
                               color: styles.mainGrey
                             }
                           }
                         }}
                >
                <span>
                  <Button
                    variant="outlined"
                    color="secondary"
                    disabled={isDisableRepostLastTransaction}
                    onClick={handleSubmit(repostLastTransaction)}
                    sx={{ width: '296px', marginLeft: '2rem' }}
                  >
                    Repost the last transaction
                  </Button>
                </span>
                </Tooltip>
              </div>
              <div className="d-flex justify-content-end mt-5">
                <Button variant="outlined"
                        onClick={() => navigate(-1)}
                        sx={{
                          width: '157px',
                          color: styles.mainGrey, borderColor: styles.mainGrey,
                          '&:hover': { borderColor: styles.mainGrey }
                        }}
                >Cancel</Button>
                <Button variant="contained"
                        hidden={mode === 'view'}
                        disabled={!formState.isValid}
                        type="submit"
                        sx={{ width: '139px', marginLeft: '2rem' }}
                >Save</Button>
              </div>
            </div>
          </form>
        </Box>
      }
      <ConfirmModal
        open={openConfirmModal}
        handleSkip={handleSubmit(sendTestTransaction)}
        handleSave={handleSubmit(handleSave)}
        handleOnClose={() => setConfirmModal(false)}
      />
    </>
  );
};

export default BaseFormComponent;
