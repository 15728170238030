import { Button, Typography } from '@mui/material';
import styles from '../../../index.module.scss';
import React, { useEffect, useState } from 'react';
import useOAuth from '../../auth/redirectUrl/service';
import './dashboard.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Company } from '../clients/clients-list';
import DeleteClientModal from '../clients/components/delete-client.modal';
import { setCurrentCompanyList } from '../../shared/redux/companySlicer';
import SetupClintModal from './setup-client.modal';
import { useLocation } from 'react-router-dom';
import { userRefresh } from '../../shared/auth.functions';
import { errorToast } from '../../shared/toast.service';

const UserDashboard = () => {
  const location = useLocation();
  const currentFirm = useSelector((state: RootState) => state.currentFirm.currentFirm);
  const companies = useSelector((state: RootState) => state.companyList.companyList);
  const queryParams = new URLSearchParams(location.search);
  const currentRealmId = queryParams.get('realmId');
  const dispatch = useDispatch();
  const { goToOauthPage } = useOAuth();
  const [selectedClient, setSelectedClient] = useState<Company | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openSetupClientModal, setSetupClientModal] = useState(false);
  const handleOpenDeleteModal = (client: Company) => {
    setSelectedClient(client);
    setOpenDeleteModal(true);
  };
  const handleSetupClientModal = (client: Company) => {
    setSelectedClient(client);
    setSetupClientModal(true);
  };
  const handleSetupClientCloseModal = () => {
    setSetupClientModal(false);
    // navigate('/user/clients');
  };
  const handleSuccess = (companies: Company[]) => {
    if (companies) {
      dispatch(setCurrentCompanyList(companies));
    }
  };
  const filterCompanies = () => {
    return companies?.filter((company) => !company.firmName) || [];
  };
  useEffect(() => {
    if (currentRealmId) {
      const company = companies?.find((company) =>
        company.realmId === currentRealmId && !company.firmName);
      if (company) {
        handleSetupClientModal(company);
      }
    }
  }, [currentRealmId, companies]);
  useEffect(() => {
    userRefresh(dispatch, currentFirm).then(() => {
    }).catch(() => {
        errorToast('An error occurred while fetching clients');
      })
      .finally(() => {
        // setLoading(false);
      });
  }, []);
  return (
    <>
      <div className="dashboardContainer">
        <h2 className="fw-bold mt-5">Dashboard</h2>
        <div className="dashboardSubtitle">
          <Typography sx={{ color: styles.mainGrey }} variant="overline">
            SETUP CLIENT
          </Typography>
        </div>
        <div className="row gap-5 qb-container">
          {Boolean(companies?.length) && filterCompanies().map((company, index) => (
            <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 qb-block">
              <div className="company-name">{company.companyName}</div>
              <div className="mt-auto d-flex justify-content-between">
                <Button variant="outlined" color="error"
                        onClick={() => handleOpenDeleteModal(company)}>Delete</Button>
                <Button variant="contained" color="error"
                        onClick={() => handleSetupClientModal(company)}>Setup</Button>
              </div>
            </div>
          ))}
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 qb-block-add">
            <Button variant="outlined" onClick={goToOauthPage}
                    sx={{
                      whiteSpace: 'nowrap',
                      fontFamily: 'AvenirMediumFont, sans-serif', fontSize: '16px', fontWeight: 600,
                      height: '36px', borderWidth: '2px', borderRadius: '4px', borderColor: styles.qbBorderGrey,
                      color: styles.qbTextBlack,
                      letterSpacing: '0.1px', lineHeight: '16px',
                      '&:hover': {
                        borderWidth: '2px', borderColor: styles.qbBorderGrey,
                        backgroundColor: styles.qbBorderGreyHover
                      }
                    }}
            >Connect to QuickBooks</Button>
          </div>
        </div>
      </div>
      {selectedClient && (
        <DeleteClientModal
          open={openDeleteModal}
          handleClose={() => setOpenDeleteModal(false)}
          onSuccess={handleSuccess}
          client={selectedClient} // Пропс для передачи в модальное окно
        />
      )}
      {selectedClient && (
        <SetupClintModal
          open={openSetupClientModal}
          handleClose={handleSetupClientCloseModal}
          client={selectedClient} // Пропс для передачи в модальное окно
        />
      )}
    </>
  );
};

export default UserDashboard;
