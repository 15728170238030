import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../shared/breadcrumb';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { Company } from '../clients-list';
import { Button, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import '../client-list.scss';
import GenericTable, { TableColumn } from '../../../shared/tables/generic.table';
import { DateTime } from 'luxon';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import active from './active.svg';
import disabled from './disable.svg';
import apiClient from '../../../shared/auth.interceptor';
import { JournalTemplateResponse } from './base-template-form';
import { errorToast } from '../../../shared/toast.service';
import { MainRoute } from '../../../../app';
import { UserRoute } from '../../userContainer';
import DeleteTemplateModal from './delete-template.modal';
import { formatTimeOffset } from '../../../shared/date-time.formater';

const journalTemplatesColumns: TableColumn[] = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'description', label: 'Description', minWidth: 100 },
  { id: 'frequency', label: 'Frequency', minWidth: 100 },
  { id: 'lastPostingDate', label: 'Last Posting Date', minWidth: 100 },
  { id: 'nextPostingDate', label: 'Next Posting Date', minWidth: 100 },
  { id: 'status', label: 'Status', minWidth: 100 },
  { id: 'actions', label: 'Action', minWidth: 100 }
];
const JournalTemplates = () => {
  const { realmId } = useParams(); // Получение realmId из URL
  const navigate = useNavigate();
  const companies = useSelector((state: RootState) => state.companyList.companyList);
  const [selectedClient, setSelectedClient] = useState<Company | null>(null);
  const [journalTemplatesData, setJournalTemplatesData] = useState<JournalTemplateResponse[] | null>(null);
  const crumbs = [
    { title: selectedClient?.firmName, href: `${MainRoute.User}${UserRoute.Clients}` },
    { title: selectedClient?.companyName },
    { title: 'Journal templates' }
  ];
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<JournalTemplateResponse | null>(null);

  const formatDateWithTimezone = (date?: string, timezone?: string) => {
    if (!date || !timezone) {
      return 'N/A';
    }
    const dt = DateTime.fromISO(date, { zone: timezone });
    const formattedDate = dt.toFormat('dd/LL/yy HH:mm');

    return `${formattedDate} ${formatTimeOffset(timezone)}`;
  };
  const getLastPostingDate = (template?: JournalTemplateResponse, timezone?: string) => {
    return formatDateWithTimezone(template?.lastPostingDate, timezone);
  };
  const getNextPostingDate = (template?: JournalTemplateResponse, timezone?: string) => {
    return formatDateWithTimezone(template?.nextPostingDate, timezone);
  };
  const handleCreateClick = () => {
    navigate(`${MainRoute.User}${UserRoute.Clients}${UserRoute.Templates}/${realmId}${UserRoute.Create}`);
  };
  const handleEditClick = (templateId?: string) => {
    navigate(`${MainRoute.User}${UserRoute.Clients}${UserRoute.Templates}/${realmId}${UserRoute.Edit}/${templateId}`);
  };
  const handleViewClick = (templateId?: string) => {
    navigate(`${MainRoute.User}${UserRoute.Clients}${UserRoute.Templates}/${realmId}${UserRoute.View}/${templateId}`);
  };
  const handleDeleteClick = (template: JournalTemplateResponse) => {
    setSelectedTemplate(template);
    setOpenDeleteModal(true);
  };
  const handleDeleteSuccess = () => {
    setOpenDeleteModal(false);
    getTemplates()
      .then(answer => {
        setJournalTemplatesData(answer.data);
      }).catch(e => {
      errorToast(`Failed getting Journal Templates`);
    });
  };
  const handleChangeStatus = async (template: JournalTemplateResponse) => {
    const updatedTemplate = { ...template, active: !template.active };
    try {
      const response = await apiClient.put(`/v1/journal/template/${template._id}`, updatedTemplate);
      if (response.status === 200 || response.status === 201) {
        getTemplates()
          .then(answer => {
            setJournalTemplatesData(answer.data);
          }).catch(e => {
          errorToast(`Failed getting Journal Templates`);
        });
      } else {
        errorToast('Failed to change status');
      }
    } catch (e) {
      errorToast('Failed to change status');
    }
  };
  const formatJournalTemplatesData = () => {
    return journalTemplatesData?.map(template => ({
      name: template.name,
      description: template.description,
      frequency: template.frequency,
      lastPostingDate: getLastPostingDate(template, selectedClient?.timezone),
      nextPostingDate: template.active ? getNextPostingDate(template, selectedClient?.timezone) : '',
      status: template.active
        ? (<a onClick={() => handleChangeStatus(template)}>
          <img alt="Active" src={active} className="me-2"></img>Active
        </a>)
        : (<a onClick={() => handleChangeStatus(template)}>
          <img alt="Disabled" src={disabled} className="me-2"></img>Disabled
        </a>),
      actions: (
        <div className="d-flex justify-content-between flex-nowrap">
          <a className="me-1" onClick={() => handleEditClick(template._id)}>
            <ModeEditOutlinedIcon color="action"/>
          </a>
          <a className="me-1" onClick={() => handleViewClick(template._id)}>
            <VisibilityOutlinedIcon color="action"/></a>
          <a onClick={() => handleDeleteClick(template)}
          ><DeleteOutlinedIcon color="error"/></a>
        </div>
      )
    }));
  };
  useEffect(() => {
    const currentCompany = companies?.find((company) => company.realmId === realmId);
    setSelectedClient(currentCompany || null);
  }, [realmId]);
  const getTemplates = async () => {
    return await apiClient.get(`/v1/journal/${realmId}`);
  };
  useEffect(() => {
    getTemplates()
      .then(answer => {
        setJournalTemplatesData(answer.data || []);
      }).catch(e => {
      errorToast(`Failed getting Journal Templates`);
    });
  }, []);
  return (
    <>
      <div className="mt-2">
        <Breadcrumbs crumbs={crumbs}/>
      </div>
      <div className="d-flex justify-content-between align-items-baseline">
        <div className="d-flex align-items-center my-4">
          <ArrowBackIcon onClick={() => navigate('/user/clients')} sx={{ fontSize: '2rem', cursor: 'pointer' }}/>
          <Typography sx={{ fontSize: '2rem', marginLeft: '1rem', fontWeight: 'bold' }}>Journal
            templates</Typography>
        </div>
        <Button onClick={handleCreateClick} className="primary" sx={{ width: '200px', gap: 1 }}><AddCircleOutlineIcon
          className=""/>
          Create Template
        </Button>
      </div>
      <GenericTable columns={journalTemplatesColumns} data={formatJournalTemplatesData()}/>
      {selectedTemplate && (
        <DeleteTemplateModal
          open={openDeleteModal}
          handleClose={handleDeleteSuccess}
          template={selectedTemplate}
        />
      )}
    </>
  );
};

export default JournalTemplates;
