import React from 'react';
import { Typography, Button } from '@mui/material';
import apiClient from '../../../shared/auth.interceptor';
import styles from '../../../../index.module.scss';
import { toast } from 'react-toastify';
import BaseModal from '../../../shared/components/base.modal';
import { errorToast } from '../../../shared/toast.service';
import { JournalTemplate, JournalTemplateResponse } from './base-template-form';

interface TimeZoneSelectModalProps {
  open: boolean;
  handleClose: () => void;
  template: JournalTemplateResponse;
}

const DeleteTemplateModal: React.FC<TimeZoneSelectModalProps> = ({ open, handleClose, template }) => {
  const handleUpdate = () => {
    apiClient.delete<JournalTemplate[]>(`/v1/journal/template/${template._id}`
    ).then(() => {
      toast.success('Client delete successfully');
    }).catch(() => {
      errorToast('Failed to delete client');
    }).finally(() => {
      handleClose();
    });
  };


  return (
    <BaseModal open={open} handleClose={handleClose}>
      <h2 className="fw-bold my-3">
        Delete Template
      </h2>
      <img alt={'Logo'} src="/logo120.svg"/>
      <p className="my-5">Are you sure you want to <br/> delete the template?</p>
      <Typography sx={{ color: styles.mainGreen, textDecorationLine: 'underline', fontSize: '1.6rem' }}>
        {template?.name}
      </Typography>
      <div className="d-flex justify-content-between my-3">
        <Button onClick={handleClose} variant="contained" sx={{ mt: 2, bgcolor: styles.mainGrey, width: '136px' }}>
          No
        </Button>
        <Button onClick={handleUpdate} variant="contained" sx={{ mt: 2, bgcolor: styles.mainBlue, width: '136px' }}>
          Yes
        </Button>
      </div>
    </BaseModal>
  );
};

export default DeleteTemplateModal;
