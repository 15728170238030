import React from 'react';
import { Autocomplete, TextField, InputAdornment } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';// Определяем тип для часовых поясов
export interface UsTimeZone {
  label: string;
  value: string;
}

export const timeZones: UsTimeZone[] = [
  { label: '(UTC-9:00) Alaska', value: 'America/Juneau' },
  { label: '(UTC-10:00) Hawaii-Aleutian', value: 'America/Adak' },
  { label: '(UTC-7:00) Mountain', value: 'America/Boise' },
  { label: '(UTC-6:00) Central', value: 'America/Chicago' },
  { label: '(UTC-5:00) Eastern', value: 'America/Detroit' },
  { label: '(UTC-8:00) Pacific', value: 'America/Los_Angeles' }
  // Добавьте другие часовые пояса по необходимости
];

export const TimeZoneNames: Record<string, string> = {
  'America/Adak': 'Hawaii-Aleutian',
  // 'America/Anchorage': 'Alaska',
  'America/Boise': 'Mountain',
  'America/Chicago': 'Central',
  // 'America/Denver': 'Mountain',
  'America/Detroit': 'Eastern',
  // 'America/Indiana/Indianapolis': 'Eastern',
  // 'America/Indiana/Knox': 'Central',
  // 'America/Indiana/Marengo': 'Eastern',
  // 'America/Indiana/Petersburg': 'Eastern',
  // 'America/Indiana/Tell_City': 'Central',
  // 'America/Indiana/Vevay': 'Eastern',
  // 'America/Indiana/Vincennes': 'Eastern',
  // 'America/Indiana/Winamac': 'Eastern',
  'America/Juneau': 'Alaska',
  // 'America/Kentucky/Louisville': 'Eastern',
  // 'America/Kentucky/Monticello': 'Eastern',
  'America/Los_Angeles': 'Pacific'
  // 'America/Menominee': 'Central',
  // 'America/Metlakatla': 'Alaska',
  // 'America/New_York': 'Eastern',
  // 'America/Nome': 'Alaska',
  // 'America/North_Dakota/Beulah': 'Central',
  // 'America/North_Dakota/Center': 'Central',
  // 'America/North_Dakota/New_Salem': 'Central',
  // 'America/Phoenix': 'Mountain',
  // 'America/Sitka': 'Alaska',
  // 'America/Yakutat': 'Alaska'
};

interface TimeZoneAutocompleteProps {
  selectedTimeZone: UsTimeZone | null;
  onSelectTimeZone: (newValue: UsTimeZone) => void;
}

const TimeZoneAutocomplete: React.FC<TimeZoneAutocompleteProps> = ({
  selectedTimeZone,
  onSelectTimeZone
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Autocomplete<UsTimeZone, false, true, false>
      value={selectedTimeZone as UsTimeZone}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onChange={(event, newValue) => onSelectTimeZone(newValue)}
      options={timeZones}
      disableClearable
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      getOptionLabel={(option) => option.label}

      renderInput={(params) => (
        <TextField
          {...params}
          label="Time Zone"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <AccessTimeIcon onClick={() => setOpen(!open)}/>
              </InputAdornment>
            )
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props}>{option.label}</li>
      )}
    />
  );
};

export default TimeZoneAutocomplete;
