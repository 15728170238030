import React, { useState, ChangeEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TextField, Button, IconButton } from '@mui/material';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { getPasswordErrorMessages, passwordValidator } from '../../validate';
import logo from '../login/assets/robodebt-logo.svg';
import '../login/login.scss';
import apiClient from '../../shared/auth.interceptor';
import { errorToast } from '../../shared/toast.service';

interface TouchedFields {
  email: boolean;
  code: boolean;
  password: boolean;
  confirmPassword: boolean;
}

interface FormErrors {
  [key: string]: string | undefined;
}

interface FormData {
  email: string;
  code: string;
  password: string;
  confirmPassword: string;
}

const SignUp: React.FC = () => {
  const navigate = useNavigate();
  const baseURL: string = process.env.REACT_APP_BASE_URL || '';

  const [formData, setFormData] = useState<FormData>({
    email: '',
    code: '',
    password: '',
    confirmPassword: ''
  });
  const [step, setStep] = useState<number>(1);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [touched, setTouched] = useState<TouchedFields>({
    email: false,
    code: false,
    password: false,
    confirmPassword: false
  });
  const [errors, setErrors] = useState<FormErrors>({});

  const validateField = (name: keyof FormData, value: string): void => {
    let newErrors: FormErrors = { ...errors };

    if (name === 'password') {
      ['length', 'uppercase', 'lowercase', 'number', 'special'].forEach(error => delete newErrors[error]);
      const passwordErrors = passwordValidator(value);
      newErrors = { ...newErrors, ...passwordErrors };
    }
    if (name === 'confirmPassword') {
      if (formData.confirmPassword && formData.password !== formData.confirmPassword) {
        newErrors.confirmPassword = 'Passwords don\'t match';
      } else {
        delete newErrors.confirmPassword;
      }
    }

    if (name === 'email' && !/\S+@\S+\.\S+/.test(value)) {
      newErrors.email = 'Please enter a valid email address';
    } else {
      delete newErrors.email;
    }

    if (name === 'code') {
      if (!value) {
        newErrors.code = 'Please enter the code';
      } else if (value.length < 6) {
        newErrors.code = 'Code must be 6 characters long';
      } else {
        delete newErrors.code;
      }
    }

    setErrors(newErrors);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name as keyof FormData]: value });
    validateField(name as keyof FormData, value);
  };

  const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    const { name } = event.target;
    setTouched({ ...touched, [name as keyof FormData]: true });
    validateField(name as keyof FormData, formData[name as keyof FormData]);
  };

  const sendCode = async () => {
    try {
      const response = await apiClient.get(`${baseURL}/v1/auth/code?email=${encodeURIComponent(formData.email)}`);

      if (response.status === 200) {
        setStep(2);
        toast.success('Code sent to your email');
      }
    } catch (error) {
      errorToast('Failed to send code');
    }
  };
  const checkCode = async () => {
    try {
      const response = await apiClient.get(
        `${baseURL}/v1/auth/code-verify?email=${encodeURIComponent(formData.email)}&code=${formData.code}`);
      if (response.status === 200) {
        setStep(3);
        toast.success('Code verified successfully');
      }
    } catch (error) {
      errorToast('Failed to verify code');
    }
  };
  const signUp = async () => {
    try {
      const response = await apiClient.post(`${baseURL}/v1/auth/sign-up`,
        formData);

      if (response.status === 200) {
        navigate('/');
      } else {
        errorToast('Unexpected response from the server');
      }
    } catch (error) {
      errorToast('Signup Error');
    }
  };

  const disableSendButton = Object.keys(errors).length > 0 || !formData.email;
  const disableVerifyButton = Object.keys(errors).length > 0 || !formData.code;
  const disableSignupButton = Object.keys(errors).length > 0 || !formData.email || !formData.password ||
                              !formData.confirmPassword;

  return (
    <div className="container-auth-page">
      <div className="main-auth-form">
        <img alt="logo" src={logo} className="logo"/>
        <form className="input-form px-3">
          <h3 className="fw-bold mb-4">Create Account</h3>
          <TextField
            fullWidth
            error={Boolean(errors.email && touched.email)}
            helperText={touched.email && errors.email}
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            label="Email"
            variant="outlined"
            margin="normal"
            disabled={step !== 1} // Отключаем поле после первого шага
          />
          {step === 1 && (
            <>
              <Button disabled={disableSendButton} variant="contained" className="login_button w-100 my-2"
                      onClick={sendCode}>Send Code</Button>
            </>
          )}

          {/* Шаг 2: Ввод полученного кода */}
          {step === 2 && (
            <>
              <TextField
                fullWidth
                error={Boolean(errors.code && touched.code)}
                helperText={touched.code && errors.code}
                type="text"
                name="code"
                value={formData.code}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                label="Verification Code"
                variant="outlined"
                margin="normal"
              />
              <Button disabled={disableVerifyButton} variant="contained" className="login_button w-100 my-2"
                      onClick={checkCode}>Verify Code</Button>
            </>
          )}

          {/* Шаг 3: Создание пароля */}
          {step === 3 && (
            <>
              <TextField
                fullWidth
                sx={{
                  whiteSpace: 'pre-line',
                  position: 'relative'
                }}
                error={Boolean(getPasswordErrorMessages(errors) && touched.password)}
                helperText={touched.password ? getPasswordErrorMessages(errors) : ''}
                type={passwordVisible ? 'text' : 'password'}
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                label="Password"
                variant="outlined"
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      sx={{
                        cursor: 'pointer', marginRight: '0.5rem',
                        position: 'absolute',
                        right: 0,
                        zIndex: 10
                      }}
                      onClick={() => setPasswordVisible(!passwordVisible)}
                      edge="end"
                      aria-label="toggle password visibility"
                    >
                      {passwordVisible ? <VisibilityOffOutlinedIcon fontSize="small"/> :
                        <RemoveRedEyeOutlinedIcon fontSize="small"/>}
                    </IconButton>
                  )
                }}
              />

              <TextField
                fullWidth
                sx={{
                  whiteSpace: 'pre-line',
                  position: 'relative'
                }}
                error={touched.confirmPassword && formData.password !== formData.confirmPassword}
                helperText={touched.confirmPassword && formData.password !== formData.confirmPassword ?
                  'Passwords do not match' : ''}
                type={passwordVisible ? 'text' : 'password'}
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                label="Confirm Password"
                variant="outlined"
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      sx={{
                        cursor: 'pointer', marginRight: '0.5rem',
                        position: 'absolute',
                        right: 0,
                        zIndex: 10
                      }}
                      onClick={() => setPasswordVisible(!passwordVisible)}
                      edge="end"
                      aria-label="toggle password visibility"
                    >
                      {passwordVisible ? <VisibilityOffOutlinedIcon fontSize="small"/> :
                        <RemoveRedEyeOutlinedIcon fontSize="small"/>}
                    </IconButton>
                  )
                }}
              />

              <Button disabled={disableSignupButton} variant="contained" className="login_button w-100 my-2"
                      onClick={signUp}>Sign Up</Button>
            </>
          )}
          <div className="need-help-div justify-content-center">
            <Link to="/">Back to Login</Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
