import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import firmReducer from './components/shared/redux/firmSlicer';
import firmListReducer from './components/shared/redux/firmListSlicer';
import companyListReducer from './components/shared/redux/companySlicer';
import  currentRealmReducer  from './components/shared/redux/currentRealmSlicer';

// Это корневой reducer, который комбинирует все reducers вместе
const rootReducer = combineReducers({
  currentFirm: firmReducer,
  firmList: firmListReducer,
  companyList: companyListReducer,
  currentRealm: currentRealmReducer
  // ...другие reducers...
});

// Конфигурация redux-persist
const persistConfig = {
  key: 'root',
  storage // использует localStorage по умолчанию
};

// Persisted reducer используется для сохранения и восстановления состояния
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/REGISTER']
      }
    })
});

export const persistor = persistStore(store);

// Тип для состояния всего приложения
export type RootState = ReturnType<typeof store.getState>;

export default store;
