import { Route, BrowserRouter, Routes } from 'react-router-dom';
import React from 'react';
import PageNotFound from './pageNotFound';
import Login from './components/auth/login/login';
import RedirectUrl from './components/auth/redirectUrl/RedirectUrl';
import UserContainer from './components/user/userContainer';
import SignUp from './components/auth/Signup/SignUp';
import ForgetPassword from './components/auth/ForgetPassword/ForgetPassword';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import UserGuard from './components/shared/user.guard';

export enum MainRoute {
  IntuitRedirect = '/intuit-signin-redirect-url',
  User = '/user',
  SignUp = '/signup',
  ForgotPassword = '/forget-password'
}

export default function App() {
  return (
    <BrowserRouter>
      <ToastContainer position="top-right" autoClose={3000}/>
      <Routes>
        <Route path="/" element={<Login/>}/>
        <Route path={MainRoute.IntuitRedirect} element={<RedirectUrl/>}/>
        <Route path={MainRoute.User + "/*"} element={
          <UserGuard>
            <UserContainer/>
          </UserGuard>}/>
        <Route path={MainRoute.SignUp} element={<SignUp/>}/>
        <Route path={MainRoute.ForgotPassword} element={<ForgetPassword/>}/>
        <Route path="*" element={<PageNotFound/>}/>
      </Routes>
    </BrowserRouter>
  );
}
