import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import RecentActorsOutlinedIcon from '@mui/icons-material/RecentActorsOutlined';
import './menu.scss';
import avatar from './avatar.svg';
import { LocalStorageKey } from '../shared/auth.functions';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddNewFirmModal from './add-new-firm.modal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setCurrentFirm } from '../shared/redux/firmSlicer';
import styles from '../../index.module.scss';
import { errorToast } from '../shared/toast.service';

const MenuComponent = () => {
  const userString = localStorage.getItem(LocalStorageKey.USER);
  // Redux Firm Name
  const currentFirm = useSelector((state: RootState) => state.currentFirm.currentFirm);
  const firmList = useSelector((state: RootState) => state.firmList.firmList);
  const dispatch = useDispatch();
  const location = useLocation(); // Получаем текущее положение

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  let userName = null;
  let email = null;
  try {
    userName = userString ? JSON.parse(userString)?.name : null;
    email = userString ? JSON.parse(userString)?.email : null;
  } catch (e) {
    errorToast('Failed to get user name');
  }
  const navigate = useNavigate();

  const menuItems = [
    { name: 'Dashboard', icon: <SpaceDashboardOutlinedIcon/>, path: '/user/dashboard' },
    { name: 'Clients', icon: <RecentActorsOutlinedIcon/>, path: '/user/clients' }
    // { name: 'Payments', icon: <AccountBalanceWalletOutlinedIcon/>, path: '/user/payments' },
    // { name: 'Details', icon: <SettingsOutlinedIcon/>, path: '/user/details' }
  ];

  const { pathname } = useLocation();
  const isActive = (path: string) => pathname.startsWith(path);
  const logout = () => {
    localStorage.clear();
    navigate('/');
  };

  // Add new Firm

  const [openAddFirmModal, setOpenAddFirmModal] = useState(false);
  const [editState, setEditState] = useState(false);
  const handleSuccess = () => {
    setOpenAddFirmModal(false);
    setEditState(false);
    changeFirms();
  };
  const handleClose = () => {
    setOpenAddFirmModal(false);
    setEditState(false);
  };
  const handleAddOpen = () => {
    setAnchorEl(null);
    setEditState(false);
    setOpenAddFirmModal(true);
  };
  const handleEditOpen = () => {
    setEditState(true);
    setOpenAddFirmModal(true);
  };
  const adjustFontSizeToFit = (containerId: string) => {
    const container = document.getElementById(containerId);
    if (!container) {
      return;
    }

    let fontSize = parseInt(window.getComputedStyle(container).fontSize);
    while (container.scrollWidth > container.offsetWidth && fontSize > 0) {
      fontSize--;
      container.style.fontSize = `${fontSize}px`;
    }
  };
  useEffect(() => {
    adjustFontSizeToFit('profile-user-name');
  }, [userName, email]);
  const changeFirms = () => {
    if (location.pathname.startsWith('/user/dashboard')) {
      navigate(`/user/dashboard`);
      return;
    }
    if (location.pathname.startsWith('/user/clients')) {
      navigate(`/user/clients`);
      return;
    }
  };
  return (
    <>
      <div className="menu-container ">
        <div className="menu-top-section">
          <div className="menu-toggle-block">
            <img src="/logo120.svg" alt="Logo" className="logo"/>
            {/*<MenuOpenIcon className="menu-icon"/>*/}
          </div>
          <div className="company-name-empty-block"></div>
          <div className="company-name-section">
            {currentFirm ?
              <span className="company-name">{currentFirm}</span> :
              <Button onClick={handleAddOpen}
                      variant="contained"
                      sx={{ width: '140px', height: '40px', marginLeft: '1rem', fontSize: '0.9rem', gap: 0 }}>
                Create Firm
              </Button>
            }
            <div>
              <Button className="change-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
              >CHANGE <ExpandMoreIcon className="menu-icon"/></Button>
            </div>
            <Menu className="menu-change-firm"
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button'
                  }}
            >{currentFirm && <MenuItem onClick={() => setAnchorEl(null)} className="p-0 mt-3">
              <div className="w-100 d-flex justify-content-between align-items-center px-3">
                <Typography
                  sx={{ color: styles.mainGreen, fontSize: '1.15rem', fontWeight: '500', letterSpacing: '0.2px' }}>
                  {currentFirm}
                </Typography>
                <Button onClick={handleEditOpen}
                        variant="text" sx={{ fontSize: '0.9rem', gap: 0 }}>
                  Edit
                </Button>
              </div>
            </MenuItem>}
              {firmList && firmList.reduce((acc, firm, index) => {
                if (firm === currentFirm) {
                  return acc;
                }
                const item: React.JSX.Element = (<MenuItem key={index} onClick={() => {
                  dispatch(setCurrentFirm(firm));
                  setAnchorEl(null);
                  changeFirms();
                }}>
                  {firm}
                </MenuItem>);
                acc.push(item);
                return acc;
              }, [] as React.JSX.Element[])}
              <hr className="blue-color my-1"></hr>
              <MenuItem onClick={handleAddOpen} className="d-flex justify-content-center"
                // sx={{ height: '2rem', color: styles.mainBlue }}
              >

                {/*<AddCircleOutlineIcon sx={{ fontSize: '1rem' }} className="me-2"/>*/}
                {/*<Typography>Create New Firm</Typography>*/}

                <Button variant="text" sx={{
                  color: styles.mainBlue, width: '240px', gap: 0
                }} startIcon={<AddCircleOutlineIcon/>}>
                  Create New Firm
                </Button>
              </MenuItem>
            </Menu>
          </div>
          <ul className="menu-options">
            {menuItems.map((item) => (
              <li key={item.name} className="menu-item-link">
                <Link to={item.path} className={`menu-item ${isActive(item.path) ? 'active' : ''}`}
                >
                  {React.cloneElement(item.icon, { sx: { marginRight: '10px' } })}
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="menu-bottom-section">
          {/*<Avatar className="avatar"/>*/}
          {/*<div className="circle-avatar"></div>*/}
          <div className="avatar-block me-3">
            <img alt="Avatar" src={avatar}/>
          </div>
          <div className="profile-section">
            <div id="profile-user-name" className="user-name">{userName || email}</div>
            <div className="profile-logout-section">
              <a className="profile-button">Profile</a>
              <a className="logout-button" onClick={logout}>LogOut</a>
            </div>
          </div>
        </div>
      </div>
      {(<AddNewFirmModal
        open={openAddFirmModal}
        handleClose={handleClose}
        onSuccess={handleSuccess}
        edit={editState}
      ></AddNewFirmModal>)}
    </>
  );
};

export default MenuComponent;
